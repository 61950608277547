<template>
  <div>
    <nav-tab
        :list="[{title: lang ==='en'?'Company Profile':'公司介绍', link: '/about/profile'}, {title: lang === 'en'?'News':'新闻动态', link: '/about/news'}, {
        title: lang === 'en'?'Exhibition':'展会信息',
        link: '/about/information'
      }, {title: lang === 'en'?'Contact':'联系我们', link: '/about/contact'}]"
        :active="lang === 'en'?'News':'新闻动态'"></nav-tab>
    <post-list :type="1"></post-list>
  </div>

</template>

<script>
import NavTab from "@/components/nav-tab/nav-tab";
import PostList from "@/components/post-list/post-list";
import {getLang} from "@/utils/lang";

export default {
  name: "news",
  components: {PostList, NavTab},
  data() {
    return {
      lang: getLang(),
      type: this.getType()
    }
  },
  methods: {
    getType() {
      return this.$route.query.type
    }
  }
}
</script>

<style scoped>

</style>
